import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="October 2019"
      subnav="release-notes">
      <div id="October2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Our work on React Native has reached a milestone—feature pairty for
          necessary components! We also added a few new props to components to
          make them more complete and wrote mobile guidelines everywhere.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Mobile guidelines for all of our{' '}
                <Link href="/components" isDesignCodeLink>
                  components
                </Link>
                .
              </Added>
              <Added>
                Guidelines for{' '}
                <Link href="/components/forms/search-input" isDesignCodeLink>
                  search
                </Link>
                .
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.34.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.34.0"
            />
            <div className={styles.changesList} />
            <Added>
              Both the <code>helpText</code> and <code>hasError</code> to the{' '}
              <Link href="/components/forms/checkbox/code#Checkbox%20Group">
                checkbox group
              </Link>{' '}
              and{' '}
              <Link href="/components/forms/radio/code#Radio%20Group">
                radio group
              </Link>
              .
            </Added>
            <Added>
              The <code>customOption</code> prop to the{' '}
              <Link href="/components/selects/lookup-multi-select/code">
                lookup multi-select
              </Link>
              .
            </Added>
            <Added>
              Both the <code>qaId</code> and <code>className</code> props to the{' '}
              <Link href="/components/dialogs/modal/code">modal</Link>.
            </Added>
            <Added>
              The <code>className</code> prop to the{' '}
              <Link href="/components/notifications/note/code">note</Link>.
            </Added>
            <Added>
              The{' '}
              <Link href="/components/forms/search-input" isDesignCodeLink>
                search input
              </Link>{' '}
              component.
            </Added>
            <Fixed>
              A variety of console errors that would appear. Major hattip to{' '}
              <Link href="https://github.com/jjstobbe">Jack</Link>!
            </Fixed>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="7.6.1"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v7.6.1"
            />
            <div className={styles.changesList}>
              <Added>
                The{' '}
                <Link href="/components/selects/single-select/design">
                  single
                </Link>
                ,{' '}
                <Link href="/components/selects/lookup-select/design">
                  lookup
                </Link>
                , and{' '}
                <Link href="/components/selects/lookup-multi-select/design">
                  lookup multi-select
                </Link>
                .
              </Added>
              <Added>
                The{' '}
                <Link href="/components/forms/search-input" isDesignCodeLink>
                  search input
                </Link>{' '}
                component.
              </Added>
              <Added>
                <code>Space</code> props to all{' '}
                <Link href="/components/forms/code">form</Link> and{' '}
                <Link href="/components/notifications/note/code">note</Link>{' '}
                components.
              </Added>
              <Added>
                <code>testID</code> and <code>accessibilityLabel</code>s to all
                components.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Fixed>An inconsistency with the modal.</Fixed>
              <Added>The search input.</Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
